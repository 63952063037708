@import url(themeSettings.css);

.mobileTable {
    display: none;
}

.table-hover:hover, tr:hover {
    cursor: pointer;
}

.filter-label {
    display: block;
}

@media screen and (max-width: 767px) {
    .mobileTable {
        display: unset;
    }

    .fullTable {
        display: none;
    }

    .react-bs-table-sizePerPage-dropdown {
        display: none;
    }
}

.react-bootstrap-table-pagination {
    margin: 0;
}

.expand-cell, .expand-cell-header {
    padding: 12px 0 !important;
    text-align: center;
}

.react-bootstrap-table-pagination .page-item.active .page-link {
    background-color: rgba(243, 0, 121, 1);
    border-color: rgba(243, 0, 121, 1);
    color: #fff;
}

.react-bootstrap-table-pagination .pagination .page-link:hover {
    background-color: rgba(243, 0, 121, 1) !important;
    color: #fff;
}

.pagination .page-link:hover {
    background-color: rgba(243, 0, 121, 1) !important;
    color: #fff !important;
}

.react-bootstrap-table-pagination .page-link {
    color: rgba(243, 0, 121, 1) !important;
}


.organizations-table-header th, th {
    border-bottom: 2px solid grey !important;
    color: var(--text-color);
}

.table-bordered th, .table-bordered td, th, td {
    border: unset;
    border-bottom: 1px solid #e9e9e9;
    color: var(--text-color);
}

.dark-theme .table-bordered th, .dark-theme .table-bordered td, .dark-theme th, .dark-theme td {
    color: #fff !important;
}
/*tr:hover th, tr:hover td {
    border: unset;
    border-bottom: 1px solid #e9e9e9;
    color: var(--light-grey);
}*/
.table-bordered {
    line-height: 1;
}

.worklist-table {
    font-size: medium;
}

.table-bordered th, .table-bordered td, th, td {
    border: unset;
    border-bottom: 1px solid #e9e9e9;
}

.table-hover tbody tr:hover, tr:hover, td:hover {
    background-color: var(--light-grey-hover);
}

.table-bordered {
    line-height: 1;
}

.highlight-row {
    background-color: var(--light-grey);
}

.highlight-red {
    background-color: #FFCCCB;
}
/*.btn-group-toggle {
    position: unset !important;
    display: block !important;
    vertical-align: unset !important;
    display: list-item;
    width: 25%;
    display: block;
    width: 100%;*/
/*height: calc(1.47em + 1rem + 2px);*/
/*padding: 0.5rem 0.875rem;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.47;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #E5E5E5;
    border-radius: 4px;*/
/* transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; */
/*background: white;
    z-index: 1111;
}*/

.btn-group-toggle .btn-primary {
    background: none !important;
    color: red !important;
    text-decoration: line-through;
    border: unset !important;
    box-shadow: none !important;
    display: block !important;
}

    .btn-group-toggle .btn-primary.active {
        color: #F30079 !important;
        text-decoration: unset !important;
    }

.btn-secondary:focus, .btn-secondary.focus {
    color: rgba(243, 0, 121, 1) !important;
}

.custom-pagination {
    height: 75px;
}

.page-link {
    color: rgba(243, 0, 121, 1) !important;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff !important;
    background-color: rgba(243, 0, 121, 1) !important;
    border-color: rgba(243, 0, 121, 1) !important;
}

.caret {
    margin: 10px 0 10px 5px;
    color: #cccccc;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid\9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
    content: "";
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid\9;
}

.pagination .page-item:first-child:not(.active) .page-link, .pagination .page-item:last-child:not(.active) .page-link, .pagination .page-item.disabled .page-link {
    background: var(--bg-color);
}

.table-bordered thead th, .table-bordered thead td {
    font-weight: bold;
}

.user-inactive {
    background-color: var(--warning-color);
}

tr > .hidden {
    display: none;
}


.ant-pagination-item a {
    color: rgba(243, 0, 121, 1) !important;
}

.ant-pagination-item-active {
    background-color: rgba(243, 0, 121, 1) !important;
    border-color: rgba(243, 0, 121, 1) !important;
    color: #fff !important;
}

    .ant-pagination-item-active a {
        color: #fff !important;
    }

.ant-table-tbody > tr.ant-table-row-selected > td {
    background-color: var(--light-pink-to-greyscale) !important;
}
